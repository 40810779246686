<template>
  <div class="container-fluid">
    <div class="d-flex flex-column mb-2">
      <span style="font-size: 24px; color: #5E5873; font-weight: 500">Media Preview #001
      </span><span style="color: #6E6B7B">
        Each category (Basic, Professional, and Business) includes the four predefined roles shown below.

      </span>
    </div>
    <div
      id="mapContainer"
    >
      <!-- slider -->
      <div
        class="card"
        style="padding: 16px; width: 470px; position: absolute; left: 10px; bottom: 10px; z-index: 1;"
      >
        <div>
          <vue-slider
            v-model="value"
            :data="data"
            :marks="marks"
            class="mb-3 ml-1 mr-1"
            :direction="direction"
          />
        </div>
      </div>
      <!-- legend card -->
      <div
        class="card"
        style="padding: 6px; width: 250px; position: absolute; right: 10px; bottom: 10px; z-index: 1;"
      >
        <div
          class="d-flex justify-content-between"
          style="padding: 6px;"
        >
          <div
            class="d-flex"
          >
            <div>
              <img src="../../../../assets/images/icons/purple-dot.png">
            </div>
            <div class="ml-1">
              CCTV
            </div>
          </div>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="hideCctv"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div
          class="d-flex justify-content-between"
          style="padding: 6px;"
        >
          <div class="d-flex">
            <div>
              <img src="../../../../assets/images/icons/green-dot.png">
            </div>
            <div class="ml-1">
              Smart Helmet
            </div>
          </div>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="hideHelmet"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div
          class="d-flex justify-content-between"
          style="padding: 6px;"
        >
          <div
            class="d-flex"
          >
            <div>
              <img src="../../../../assets/images/icons/pink-dot.png">
            </div>
            <div class="ml-1">
              Smart Glasses
            </div>
          </div>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="hideGlasses"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div
          class="d-flex justify-content-between"
          style="padding: 6px;"
        >
          <div
            class="d-flex"
          >
            <div>
              <img src="../../../../assets/images/icons/grey-dot.png">
            </div>
            <div class="ml-1">
              Drone Data
            </div>
          </div>
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="hideDrone"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <transition
      v-if="playMedia == 'video'"
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-if="showMoreFiles"
        style="position: relative"
        class="mt-2 mb-3 border rounded row m-0 bg-white d-flex justify-content-start"
      >
        <div
          style="position: absolute; right: -10px; top: -20px"
          class="onHover rounded bg-white p-1"
          @click="showMoreFiles = !showMoreFiles"
        > <feather-icon
          icon="ArrowLeftCircleIcon"
          class="mr-75"
        /><span style="color: #5E5873; font-weight: 500">Back</span></div>
        <div
          class="row"
          style="position: relative"
        >
          <div
            v-for="(vid, index) in listVid"
            :key="index"
            class="col-6"
          >
            <video
              controls
              style="width: 100%"
              class="pr-1 pb-1 pl-2 pt-2"
              @click="modalOpenVid(vid.path)"
            >
              <source
                :src="`${publicPath}${vid.path}`"
                autostart="false"
                type="video/mp4"
              >
            </video>
          <!-- {{ vid.path }} -->

          </div>
        </div>
        <b-modal
          id="modal-video"
          hide-footer
          centered
          hide-header
          size="lg"
          body-class="noBackground"
        >
          <div
            class="d-flex justify-content-center"
            style="position: relative"
          >

            <vue-plyr
              :options="options"
            >
              <video
                controls
                crossorigin
                playsinline
              >
                <source
                  size="1080"
                  :src="`${publicPath}${srcVid}`"
                  type="video/mp4"
                >
                <track
                  default
                  kind="captions"
                  label="English captions"
                  src="/path/to/english.vtt"
                  srclang="en"
                >
              </video>
            </vue-plyr>
            <div
              class="bg-white divCloseBtn"
              style="position: absolute; right: -190px; top: -18px;"
              @click="imgCloseVid"
            >
              <feather-icon
                icon="XIcon"
                size="44"
                class="align-middle text-body closeBtn"
              />
            </div>
          </div>
        <!-- <div :style="{'background-image': 'url(' + require(`${publicPath}${srcImg}`) + ')'}" /> -->
        </b-modal>
      </div></transition>
    <transition
      v-else
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-if="showMoreFiles"
        style="position: relative"
        class="mt-2 mb-3 border rounded row m-0 bg-white d-flex justify-content-start"
      >
        <div
          style="position: absolute; right: -10px; top: -20px"
          class="onHover rounded bg-white p-1"
          @click="showMoreFiles = !showMoreFiles"
        > <feather-icon
          icon="ArrowLeftCircleIcon"
          class="mr-75"
        /><span style="color: #5E5873; font-weight: 500">Back</span></div>
        <div
          v-for="(img, index) in listImg"
          :key="index"
          style="position: relative"
        >
          <div
            style="position: absolute; width: inherit;"
            class="showHover p-1 rounded"
          >
            <span style="color: white">{{ img.title }}</span>
          </div>
          <img
            class="myImg pr-1 pb-1 onHover"
            :src="`${publicPath}${img.path}`"
            @click="modalOpen(img.path)"
          >
          <!-- </div> -->
        </div>

        <b-modal
          id="modal-image"
          hide-footer
          centered
          hide-header
          size="sm"
          body-class="noBackground"
        >
          <div
            class="d-flex justify-content-center"
            style="position: relative"
          >

            <img
              style="display: block;
              width: auto;
              max-height: 100%"
              width="900"
              height="700"
              :src="`${publicPath}${srcImg}`"
            >
            <div
              class="bg-white divCloseBtn"
              style="position: absolute; right: -190px; top: -18px;"
              @click="imgClose"
            >
              <feather-icon
                icon="XIcon"
                size="44"
                class="align-middle text-body closeBtn"
              />
            </div>
          </div>
        <!-- <div :style="{'background-image': 'url(' + require(`${publicPath}${srcImg}`) + ')'}" /> -->
        </b-modal>

      </div></transition>
    <transition
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-show="showMoreFiles === false"
        class="mt-2  mb-3 border  rounded row m-0 justify-content-between bg-white"
      >
        <div
          class="d-flex flex-column  p-2 col-12"
          :class="playMedia === 'pdf' ? 'col-12':'col-8 col-sm-12 col-md-8'"
          style="position: relative"
        >
          <div
            v-if="playMedia === 'video'"
          >
            <vue-plyr
              :options="options"
            >
              <video
                controls
                crossorigin
                playsinline
                data-poster="poster.jpg"
              >
                <source
                  size="1080"
                  :src="`${publicPath}files/vid/vid1.mp4`"
                  type="video/mp4"
                >
                <track
                  default
                  kind="captions"
                  label="English captions"
                  src="/path/to/english.vtt"
                  srclang="en"
                >
              </video>
            </vue-plyr>
          </div>
          <div
            v-else-if="playMedia === 'cctv'"
            style="width: auto;"
            class=""
          >
            <!-- style="width: 100%; height: 100%" -->
            {{ activate() }}
            <div
              v-show="cctvDisplay"
              style="width: 100%; height: 100%"
            >
              <!-- <div class="">
                <iframe
                  class=""
                  style="height: 500px; overflow: hidden; width: 100%"
                  src="http://vetter.viewnetcam.com:50000/nphMotionJpeg?Resolution=640x480&Quality=Clarity"
                />

              </div> -->
              <div class="d-flex justify-content-center">
                <div
                  style="width: 100%;"
                >
                  <!-- <video-player
                    ref="videoPlayer"
                    :options="playerOptions"
                    style="width: 780px; height: 400px"
                    @ready="onPlayerReadied"
                    @timeupdate="onTimeupdate"
                  /> -->
                  <video
                    style="width: 100%"
                    autoplay
                    class="pr-1 pb-1 pl-2 pt-2"
                  >
                    <source
                      :src="`${publicPath}files/vid/footage.mp4`"
                      autostart="true"
                      type="video/mp4"
                    >
                  </video>
                  {{ activate() }}
                </div>
              </div>
              <!--
              <video
                id="videoStream"
                width="100%"
                height="100%"
                controls
                autoplay
              /> -->
              <!-- {{ liveStream("//nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8") }} -->
              <!-- {{ liveStream("http://54.169.11.71:5080/PRISM/streams/stream.m3u8") }} -->
              <!-- {{ liveStream("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8") }} -->
              <!-- {{ liveStream("http://cctvcnch5c.v.wscdns.com/live/cctv15_2/index.m3u8") }} -->
            </div>
          </div>
          <div
            v-else-if="playMedia === 'pdf'"
            style="height: inherit; width: inherit;"
          >  <pdf-viewer /> </div>
          <div
            v-else
            style="height: inherit; width: inherit;"
          >
            <img
              src="@/assets/images/gallery/photos.png"
              style="height: inherit; width: inherit;"
            >
          </div>

          <div
            v-show="playMedia== 'photos' || playMedia== 'video'"
            style="position: absolute; right: 50px; top: 50px"
            class="onHover rounded bg-white p-1"
            @click="showMoreFiles = !showMoreFiles"
          > <feather-icon
            icon="GridIcon"
            class="mr-75"
          /><span style="color: #5E5873; font-weight: 500">Show all {{ playMedia === 'video' ? 'videos': 'photos' }}</span></div>
          <div class="my-2 mx-2">
            <div class="mb-2"><b-badge variant="danger">
              Urgent
            </b-badge></div>
            <span style="font-size: 18px; color: #5E5873; font-weight: 500">HS1230-Media Preview {{ currentMedia }}
            </span>
            <div class="row m-0 align-items-center  justify-content-between">
              <div class="d-flex align-items-center">
                <span>09:00 AM

                </span> <div
                  class="mx-1"
                  style="background-color:#5E5873; height: 5px; width: 5px; border-radius: 50%"
                /><span>30/09/2020
                </span>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div
                  class="d-flex align-items-center btn btn-primary"
                  style="height: 40px"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                    size="20"
                  /> <span>Download</span>
                </div>
                <div
                  class="ml-2 d-flex align-items-center justify-content-center"
                  style="border: 2px solid #00AE9D; height: 40px; width: 40px;
                 border-radius: 5px;"
                >
                  <feather-icon
                    icon="Share2Icon"
                    class="mr-50 text-primary"
                    size="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="playMedia !== 'pdf'"
          class="border-left row m-0 justify-content-center mt-1 col-sm-12 col-md-4 align-items-start col-lg-4 col-12 bg-white"
        >
          <div class="d-flex justify-content-center flex-column col-12">
            <span style="color: #5E5873; font-weight: 500; font-size: 17px ">Media Details</span>

            <div class="mt-1 d-flex justify-content-center">
              <div
                class="btn-group bg-white row"
                role="group"
                aria-label="First group"
                style="width: 100%"
              >
                <div
                  type="button"
                  class="btn btn-light"
                  @click="isInfos()"
                >
                  Info
                </div>
                <div
                  type="button"
                  class="btn btn-light"
                  @click="isProfile()"
                >
                  Profile
                </div>
                <!-- <div
                  type="button"
                  class="btn btn-light"
                  @click="isAuditTrail()"
                >
                  Audit Trail
                </div> -->
              </div>
            </div>
            <!-- <div
              style="border: 1px solid #5E5873;"
              class="mt-1 d-flex col-12 rounded justify-content-between align-items-center"
            >
              <div
                style="border-right: 1px solid #5E5873 "
                class="col"
                @click="isInfos()"
              >
                <div class="my-1 text-center">
                  Info
                </div>
              </div>
              <div
                class="col"
                @click="isProfile()"
              >
                <div class="my-1 text-center">
                  Profile
                </div>
              </div>
              <div
                style="border-left: 1px solid #5E5873; overflow: auto"
                class="col"
                @click="isAuditTrail()"
              >
                <div
                  class="my-1 text-center"
                  style="overflow: auto"
                >
                  Audit Trail
                </div>
              </div>
            </div> -->
            <div
              class="col "
              style="height: 10px"
            />
            <div
              v-if="isInfo"
              class="mt-1"
              style="width: 100%"
            >
              <hr>

              <span
                class=""
                style="color: #5E5873; font-weight:600;"
              >General Information</span>
              <div class="mt-1 d-flex flex-column">
                <div
                  v-for="(item, index) in sub.info.information"
                  :key="index"
                  class="d-flex justify-content-between"
                  style="color: #6E6B7B; font-weight:400; margin-bottom: 10px"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.data }}</span>

                </div>

              </div>

            </div>
            <div
              v-else-if="isProfile"
              class="pl-2 pt-2"
            >
              <hr>
              <div class="mt-1 d-flex flex-column">
                <div
                  v-for="(item, index) in subProfile.info.information"
                  :key="index"
                  class="d-flex justify-content-between"
                  style="color: #6E6B7B; font-weight:400; margin-bottom: 10px"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.data }}</span>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>
<style>
/* .video-js {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
} */
.showHover{
  display: none;
}
.onHover:hover>.showHover {
  background: turquoise;
  display: inline;
  z-index: 55555;
}
.onHover{
  transition: transform .2s;
}
.onHover:hover {
  transform: scale(1.2);
  z-index: 2;
}
.divCloseBtn{
  transition: transform .2s;
  border-radius: 6px;
}
.divCloseBtn:hover {
  transform: scale(1.2);
}
.closeBtn{
  color: #5E5873;
}
.noBackground{
background-color: rgb(154, 154, 154)!important;
width:"900";
            height:"700";
}
#mapContainer {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 40vh!important;
}

.marker {
   /* background-image: url('mapbox-icon.png'); */
  background-size: cover;
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}

.greendot {
  background-image: url('../../../../assets/images/icons/green-dot.png');
}

.purpledot {
  background-image: url('../../../../assets/images/icons/purple-dot.png');
}

.pinkdot {
  background-image: url('../../../../assets/images/icons/pink-dot.png');
}

.greydot {
  background-image: url('../../../../assets/images/icons/grey-dot.png');
}

.hide {
  display: none;
}

.line-through {
  text-decoration: line-through;
}

.mapboxgl-canvas {
  width: 100% !important;
}
</style>
<script>
// import { GGanttChart, GGanttRow } from 'vue-ganttastic'
// import {
//   BCard, BCol, BRow, BFormSelect, BFormCheckbox,
// } from 'bootstrap-vue'
// import VueSlider from 'vue-slider-component'
// import Hls from 'hls.js'
import mapboxgl from 'mapbox-gl'
import VueSlider from 'vue-slider-component'
import { BBadge, BFormCheckbox } from 'bootstrap-vue'
import store from '@/store/index'
import pdfViewer from '@/views/pages/collection/doc/component/PDFView.vue'

const isProduction = process.env.NODE_ENV === 'production'
export default {
  components: {
    VueSlider,
    BBadge,
    BFormCheckbox,
    pdfViewer,

  },
  data(){
    return {
      playerOptions: {
        overNative: true,
        // fluid: true,
        // aspectRatio: '9:16',
        fill: true,
        autoplay: true,
        controls: true,
        techOrder: ['html5'],
        sourceOrder: true,
        flash: {
          hls: { withCredentials: false },
          swf: isProduction ? '/vue-videojs-demo/static/media/video-js.swf' : '/static/media/video-js.swf',
        },
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            type: 'rtmp/mp4',
            src: 'rtmp://13.229.242.159/LiveApp/alpr',
          },
          {
            withCredentials: false,
            type: 'application/x-mpegURL',
            src: 'http://54.169.11.71:5080/PRISM/streams/stream.m3u8',
          },
        ],
        poster: isProduction ? '/vue-videojs-demo/static/images/logo.png' : '/static/images/logo.png',
      },
      showMoreFiles: false,
      playMedia: 'video',
      currentMedia: '(CCTV Testing 1)',
      listVid: [
        {
          title: 'Vid 19',
          path: 'files/vid/vid1.mp4',
        },
        // {
        //   title: 'Vid 20',
        //   path: 'files/vid/DJI_0020.mp4',
        // },
        // {
        //   title: 'Vid 21',
        //   path: 'files/vid/DJI_0021.mp4',
        // }, {
        //   title: 'Vid 22',
        //   path: 'files/vid/DJI_0022.mp4',
        // }, {
        //   title: 'Vid 23',
        //   path: 'files/vid/DJI_0023.mp4',
        // }, {
        //   title: 'Vid 24',
        //   path: 'files/vid/DJI_0024.mp4',
        // },
        {
          title: 'Vid 25',
          path: 'files/vid/vid2.mp4',
        }, {
          title: 'Vid 26',
          path: 'files/vid/vid3.mp4',
        }, {
          title: 'Vid 27',
          path: 'files/vid/vid4.mp4',
        },
        // {
        //   title: 'Vid 28',
        //   path: 'files/vid/DJI_0028.mp4',
        // }, {
        //   title: 'Vid 29',
        //   path: 'files/vid/DJI_0029.mp4',
        // }, {
        //   title: 'Vid 30',
        //   path: 'files/vid/DJI_0030.mp4',
        // }, {
        //   title: 'Vid 31',
        //   path: 'files/vid/DJI_0031.mp4',
        // },
        {
          title: 'Vid 32',
          path: 'files/vid/vid5.mp4',
        },
        // {
        //   title: 'Vid 33',
        //   path: 'files/vid/DJI_0033.mp4',
        // }, {
        //   title: 'Vid 34',
        //   path: 'files/vid/DJI_0034.mp4',
        // },
      ],
      cctvDisplay: false,
      listImg: [
        {
          title: 'Image Title 9',
          path: 'files/img/img9.png',
        },
        {
          title: 'Image Title 8',
          path: 'files/img/img8.png',
        },
        {
          title: 'Image Title 7',
          path: 'files/img/img7.png',
        },
        {
          title: 'Image Title 6',
          path: 'files/img/img6.png',
        },
        {
          title: 'Image Title 5',
          path: 'files/img/img5.png',
        },
        {
          title: 'Image Title 4',
          path: 'files/img/img4.png',
        },
        {
          title: 'Image Title 3',
          path: 'files/img/img3.png',
        },
        {
          title: 'Image Title 2',
          path: 'files/img/img2.png',
        },
        {
          title: 'Image Title 1',
          path: 'files/img/img1.png',
        },
        {
          title: 'Image Title 10',
          path: 'files/img/img10.png',
        },
        {
          title: 'Image Title 11',
          path: 'files/img/img11.png',
        },
        {
          title: 'Image Title 12',
          path: 'files/img/img12.png',
        },
      ],
      publicPath: process.env.BASE_URL,
      srcImg: "",
      srcVid: "",
      options: { quality: { default: '1080p' } },
      isInfo: true,
      isLog: false,
      isJson: false,
      sub: {
        info: {
          information: [
            {
              name: 'Image Sensor',
              data: '1/2.8” 3M PS CMOS',
            },
            {
              name: 'Streaming',
              data: 'Multiple Streaming',
            },
            {
              name: 'Zoom Ratio',
              data: 'N/A',
            },
            {
              name: 'Media Type',
              data: 'Photo',
            },
            {
              name: 'Video Compression',
              data: 'H.264, MJPEG',
            },
            {
              name: 'Analogue Out',
              data: 'CVBS 1.0Vp-p',
            },
          ],
        },
      },
      subProfile: {
        info: {
          information: [
            {
              name: 'Max Resolution',
              data: '2048 x 1536',
            },
            {
              name: 'Max Frame Rate',
              data: '20fps at 3M',
            },
            {
              name: 'Audio Compression',
              data: 'G.711 u-law',
            },
            {
              name: 'Memory Slot',
              data: 'Yes (SD/SDHC)',
            },
            {
              name: 'Alarm I/O',
              data: '1/1',
            },
            {
              name: 'Day & Night',
              data: 'Yes',
            },

          ],
        },
      },
      items: [
        {
          temporary_building: 'Genset', priority_type: 'String',
        },
        {
          temporary_building: 'Schedule Waste', priority_type: 'String',
        },
        {
          temporary_building: 'Structure Steel Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Fabrication Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Foldable Gate', priority_type: 'String',
        },
        {
          temporary_building: 'TNB Sub Station', priority_type: 'String',
        },
        {
          temporary_building: 'Septic Tank', priority_type: 'String',
        },
      ],
      src: [],
      sources: ["http://vjs.zencdn.net/v/oceans.mp4"],
      accessToken: 'pk.eyJ1IjoicmV4emFjazkiLCJhIjoiY2sxYTF6d2QyMDRlZjNkdDlyM3Z5N24zMSJ9.U5TuDBZwdUsnwntE1h2w6w',
      value: '1.6.2020',
      data: ['1.6.2020', '2.6.2020', '3.6.2020', '4.6.2020', '5.6.2020', '6.6.2020', '7.6.2020', '8.6.2020', '9.6.2020', '10.6.2020', '11.6.2020', '12.6.2020', '13.6.2020', '14.6.2020', '15.6.2020', '16.6.2020', '17.6.2020', '18.6.2020', '19.6.2020', '20.6.2020', '21.6.2020', '22.6.2020', '23.6.2020', '24.6.2020', '25.6.2020', '26.6.2020', '27.6.2020', '28.6.2020', '29.6.2020', '30.6.2020'],
      marks: {
        '1.6.2020': '1.6.2020',
        '5.6.2020': '5.6.2020',
        '10.6.2020': '10.6.2020',
        '15.6.2020': '15.6.2020',
        '20.6.2020': '20.6.2020',
        '25.6.2020': '25.6.2020',
        '30.6.2020': '30.6.2020',
      },
      dir: 'ltr',
    }
  },

  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken
    // this.liveStream()

    // const map = new mapboxgl.Map({
    //   container: 'mapContainer',
    //   style: 'mapbox://styles/mapbox/streets-v11',
    //   center: [103.811279, 1.345399],
    //   zoom: 12,
    //   maxBounds: [
    //     [103.6, 1.1704753],
    //     [104.1, 1.4754753],
    //   ],
    // })

    // const nav = new mapboxgl.NavigationControl()
    // map.addControl(nav, 'bottom-left')

    // const marker = new mapboxgl.Marker()
    //   .setLngLat([103.811279, 1.345399])
    //   .addTo(map)
    // console.log(marker)

    // const geolocate = new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true,
    //   },
    //   trackUserLocation: true,
    // })

    // map.addControl(geolocate, "bottom-left")
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            message: 'Smart Helmet',
            type: 'pdf',
            iconSize: [40, 40],
          },
          geometry: {
            type: 'Point',
            coordinates: [101.737732, 2.962813],
          },
          iconClass: 'greendot',
        },
        {
          type: 'Feature',
          properties: {
            message: 'CCTV',
            type: 'cctv',
            iconSize: [40, 40],
          },
          geometry: {
            type: 'Point',
            coordinates: [101.738055, 2.962707],
          },
          iconClass: 'purpledot',
        },
        {
          type: 'Feature',
          properties: {
            message: 'Smart Glasses',
            type: 'photos',
            iconSize: [40, 40],
          },
          geometry: {
            type: 'Point',
            coordinates: [101.738339, 2.962656],
          },
          iconClass: 'pinkdot',
        },
        {
          type: 'Feature',
          properties: {
            message: 'Drone Data',
            type: 'video',
            iconSize: [40, 40],
          },
          geometry: {
            type: 'Point',
            coordinates: [101.738141, 2.962886],
          },
          iconClass: 'greydot',
        },
      ],
    }

    const map = new mapboxgl.Map({
      container: 'mapContainer', // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      // center: [-68.137343, 45.137451], // starting position
      center: [101.73745667145212, 2.9626747164285114],
      zoom: 18, // starting zoom
    })

    const nav = new mapboxgl.NavigationControl()
    map.addControl(nav, 'top-right')

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    })

    map.on('load', () => {
      // Add a data source containing GeoJSON data.
      // map.addSource('aerodyne-square', {
      //   type: 'geojson',
      //   data: {
      //     type: 'Feature',
      //     geometry: {
      //       type: 'Polygon',
      //       // These coordinates outline aerodyne-square.
      //       coordinates: [
      //         [
      //           [101.652066, 2.901536],
      //           [101.652650, 2.901197],
      //           [101.652296, 2.900632],
      //           [101.651726, 2.901087],
      //           [101.652066, 2.901536],
      //         ],
      //       ],
      //     },
      //   },
      // })

      // // Add a new layer to visualize the polygon.
      // map.addLayer({
      //   id: 'aerodyne-square',
      //   type: 'fill',
      //   source: 'aerodyne-square', // reference the data source
      //   layout: {},
      //   paint: {
      //     'fill-color': '#0080ff', // blue color fill
      //     'fill-opacity': 0.5,
      //   },
      // })
      // // Add a black outline around the polygon.
      // map.addLayer({
      //   id: 'outline',
      //   type: 'line',
      //   source: 'aerodyne-square',
      //   layout: {},
      //   paint: {
      //     'line-color': '#000',
      //     'line-width': 3,
      //   },
      // })

      geojson.features.forEach(marker => {
        const el = document.createElement('div')
        // const width = marker.properties.iconSize[0]
        // const height = marker.properties.iconSize[1]
        el.className = `marker ${marker.iconClass}`
        // el.style.backgroundImage = `url(../../../../assets/images/icons/green-dot.png)`
        // el.style.width = `${width}px`
        // el.style.height = `${height}px`
        // el.style.backgroundSize = '100%'
        el.style.cursor = 'pointer'

        el.addEventListener('click', () => {
          // window.alert(marker.properties.message)
          this.currentMedia = marker.properties.message
          this.playMedia = marker.properties.type
          this.showMoreFiles = false
        })

        el.addEventListener('mouseenter', () => {
          map.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = marker.geometry.coordinates.slice()
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          // while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
          //   coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360
          // }

          coordinates[0] += 360

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(marker.properties.message).addTo(map)
        })

        el.addEventListener('mouseleave', () => {
          map.getCanvas().style.cursor = ''
          popup.remove()
        })

        // Add markers to the map.
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map)
          .setPopup(popup)
      })
    })
  },
  methods: {
    onPlayerReadied() {
      if (!this.initialized) {
        this.initialized = true
        // eslint-disable-next-line no-underscore-dangle
        this.currentTech = this.player.techName_
      }
    },
    // record current time
    onTimeupdate(e) {
      // eslint-disable-next-line no-underscore-dangle
      console.log('currentTime', e.cache_.currentTime)
    },
    activate() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.cctvDisplay = true, 500)
    },
    liveStream(url){
      this.playerOptions.sources[1].src = url
    },
    imgClose(){
      this.$bvModal.hide('modal-image')
    },
    imgCloseVid(){
      this.$bvModal.hide('modal-video')
    },

    modalOpen(srcs) {
      this.srcImg = srcs
      this.$bvModal.show('modal-image')
    },
    modalOpenVid(srcs) {
      console.log(srcs)
      this.srcVid = srcs
      this.$bvModal.show('modal-video')
    },
    isInfos(){
      this.isInfo = true
      this.isLog = false
    },
    isProfile(){
      this.isJson = false
      this.isInfo = false
      this.isLog = false
    },
    isAuditTrail(){
      this.isLog = true
      this.isInfo = false
    },
    showAllVideos(){
      this.$router.push({ name: 'media-gallery-details-all' })
    },
    hideCctv() {
      const purpledot = document.getElementsByClassName('purpledot')
      purpledot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('cctv')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('cctv')[0].className += ' line-through'
        }
      })
    },
    hideHelmet() {
      console.log('sdsdds')
      const greendot = document.getElementsByClassName('greendot')
      greendot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('smartHelmet')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('smartHelmet')[0].className += ' line-through'
        }
      })
    },
    hideGlasses() {
      const pinkdot = document.getElementsByClassName('pinkdot')
      pinkdot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('smartGlasses')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('smartGlasses')[0].className += ' line-through'
        }
      })
    },
    hideDrone() {
      const greydot = document.getElementsByClassName('greydot')
      greydot.forEach(data => {
        const dot = data
        if (dot.classList.contains('hide')){
          dot.classList.remove('hide')
          // document.getElementsByClassName('droneData')[0].classList.remove('line-through')
        } else {
          dot.className += ' hide'
          // document.getElementsByClassName('droneData')[0].className += ' line-through'
        }
      })
    },
  },
}
</script>
